import {
  IconActivity,
  IconAlertTriangle,
  IconArrowLeft,
  IconBuildingEstate,
  IconDeviceFloppy,
  IconFileInvoice,
  IconInfoSquareRounded,
  IconSparkles,
  IconTrash,
  IconUser,
  IconUserCircle,
  IconUserStar,
} from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Swal from "sweetalert2";
import CardLoading from "../components/common/CardLoading";
import Date from "../components/common/Date";
import Loader from "../components/common/Loader";
import Skeleton from "../components/common/Skeleton";
import Spinner from "../components/common/Spinner";
import Tabs, { OptionContent } from "../components/common/Tabs";
import Toggleable from "../components/common/Toggleable";
import CvUploader from "../components/profile/CvUploader";
import ProfileIcon from "../components/profile/ProfileIcon";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useHttp } from "../providers/HttpProvider";
import { useProfile } from "../providers/ProfileProvider";
import { Roles } from "../roles";
import { getRawDate } from "../shared/others/formatDate";
import { askForConfirmation } from "../shared/popups/askForConfirmation";
import toast from "../shared/popups/toast";
import { updateProfileObjectProperty } from "../shared/profile/updateProfileObjectProperty";
import { activityInputs } from "../static/profile/activityInputs";
import { informationsInputs } from "../static/profile/informationsInputs";
import {
  Profile,
  ProfileInput,
  Profile as ProfileProps,
  Role,
} from "../types/profile";

const DirectoryCrafter = (): ReactElement => {
  const [changesHaveBeenMadeOnProfile, setChangesHaveBeenMadeOnProfile] =
    useState<boolean>(false);
  const [changesHaveBeenMadeOnRoles, setChangesHaveBeenMadeOnRoles] =
    useState<boolean>(false);
  const [profileProfessionalStatus, setProfileProfessionalStatus] =
    useState<string>();
  const [profileTechnicalSkills, setProfileTechnicalSkills] = useState<
    string[]
  >([]);
  const [openToggleable, setOpenToggleable] = useState<string>(
    "informationsToggleable",
  );
  const [fieldsWithChanges, setFieldsWithChanges] = useState<string[]>([]);
  const { editCrafterProfile, crafters, setCrafters } = useDirectory();
  const [rolesLoading, setRolesLoading] = useState<boolean>(true);
  const [openToWork, setOpenToWork] = useState<boolean>(false);
  const [initialRoles, setInitialRoles] = useState<Role[]>([]);
  const [activeRoles, setActiveRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validRoles, setValidRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cvFilePath, setCvFilePath] = useState<string>();
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState<Profile>();
  const { roles, profile: adminProfile } = useProfile();
  const navigate = useNavigate();
  const { email } = useParams();
  const { loading: httpLoading, get, post, del } = useHttp();

  useEffect(() => {
    if (adminProfile) {
      if (!roles.some((role) => role === Roles.ADMIN)) {
        navigate("/directory");
      }
    }
  }, [roles, adminProfile, navigate]);

  useEffect(() => {
    const initValidRoles = async (): Promise<void> => {
      const getValidRolesResult = await get("/admin/roles");
      setValidRoles(getValidRolesResult.roles);

      const result = await get(`/admin/roles/${profile?.idpId}`);
      setInitialRoles(result.roles);
      setActiveRoles(result.roles);

      setTimeout(() => setRolesLoading(false), 500);
    };

    profile && !httpLoading && void initValidRoles();
  }, [httpLoading, profile, get]);

  useEffect(() => {
    const initProfile = (): void => {
      if (email) {
        const profile = crafters?.find((crafter) => crafter.email === email);

        setProfileProfessionalStatus(profile?.professional_status);
        setProfileTechnicalSkills(
          profile?.technical_skills?.length
            ? profile?.technical_skills.split(";")
            : [],
        );

        setProfile(profile);
      } else {
        navigate("/directory");
      }
    };

    (crafters ?? []).length > 0 && initProfile();
  }, [crafters, profile, navigate, email]);

  useEffect(() => {
    setProfileProfessionalStatus(profile?.professional_status);
    setProfileTechnicalSkills(
      profile?.technical_skills?.length
        ? profile?.technical_skills.split(";")
        : [],
    );
    setOpenToWork(profile?.openToWork ?? false);
  }, [profile]);

  useEffect(() => {
    const initCv = async (): Promise<void> => {
      const result = await get(`/profiles/${profile?.email}/cv`);
      profile && setCvFilePath(result.cvDownloadUrl);
    };

    profile && !httpLoading && void initCv();
  }, [profile, httpLoading, get]);

  const handleSaveChanges = async (formValues: ProfileProps): Promise<void> => {
    formValues.technical_skills = profileTechnicalSkills?.length
      ? profileTechnicalSkills.join(";")
      : "";

    Object.keys(formValues).forEach((key): void => {
      if (
        !fieldsWithChanges.some((field: string) => field === key) &&
        !String(formValues[key as keyof ProfileProps]).length &&
        key !== "technical_skills"
      ) {
        delete formValues[key as keyof ProfileProps];
      }
    });

    let updatedProfile: ProfileProps = { ...profile } as ProfileProps;

    Object.entries(formValues).forEach(([key, value]): void => {
      updatedProfile = updateProfileObjectProperty(
        key as keyof ProfileProps,
        value,
        updatedProfile,
      );
    });

    if (profileProfessionalStatus !== "none") {
      updatedProfile.professional_status = profileProfessionalStatus;
    }

    try {
      if (profile?.email) {
        setIsLoading(true);

        setChangesHaveBeenMadeOnProfile(false);
        await editCrafterProfile(
          {
            ...updatedProfile,
            openToWork,
          },
          profile.email,
        );

        setIsLoading(false);
        toast("success", "Modifications enregistrées !");
      }
    } catch (error: any) {
      toast("error", error);
    }
  };

  const handleInputChange = (changedInput: string): void => {
    setChangesHaveBeenMadeOnProfile(true);

    const updatedChangedFieldsList = [...fieldsWithChanges];
    updatedChangedFieldsList.push(changedInput);

    setFieldsWithChanges(updatedChangedFieldsList);
  };

  const handleCancelProfileChanges = (): void => {
    const newTechnicalSkills = profile?.technical_skills?.length
      ? profile?.technical_skills.split(";")
      : [];
    setProfileTechnicalSkills(newTechnicalSkills);

    setProfileProfessionalStatus(profile?.professional_status);
    setChangesHaveBeenMadeOnProfile(false);
    reset();
  };

  const handleRolesChanges = (role: Role, checked: boolean): void => {
    const roles: Role[] = [...activeRoles];

    if (checked) {
      roles.push(role);
    } else {
      const roleIndex = activeRoles.findIndex((r) => role.id === r.id);
      roles.splice(roleIndex, 1);
    }

    setActiveRoles(roles);
    setChangesHaveBeenMadeOnRoles(true);
  };

  const handleSubmitRoles = async (): Promise<void> => {
    try {
      if (profile) {
        setChangesHaveBeenMadeOnRoles(false);
        setRolesLoading(true);

        await post(`/admin/roles/${profile?.idpId}/update`, {
          initialRoles,
          updatedRoles: activeRoles,
        });

        const updatedCrafterProfile = {
          ...profile,
          roles: activeRoles.map((r) => r.name),
        };
        await editCrafterProfile(updatedCrafterProfile, profile.email);

        setRolesLoading(false);
        toast("success", "Rôles mis à jour !");
      }
    } catch (error: any) {
      setChangesHaveBeenMadeOnRoles(true);
      void toast("error", error);
    }
  };

  const handleDeleteAccount = async (): Promise<void> => {
    try {
      const actionConfirmed = await askForConfirmation(
        `Êtes-vous sûr(e) de vouloir supprimer le compte de ${profile?.firstname} ${profile?.lastname} ?`,
      );

      if (actionConfirmed) {
        setLoading(true);

        const { userHaveAGoogleWorkspaceAccount } = await del(
          "/admin/accounts",
          {
            email: profile?.email ?? "",
            googleWorkspaceAccountEmail: `${profile?.firstname?.toLowerCase()}.${profile?.lastname?.toLowerCase()}@socraft.ch`,
          },
        );

        const email = profile?.email;
        const updatedCraftersList = [...(crafters ?? [])];
        const crafterIndex = crafters?.findIndex((c) => c.email === email);

        if (crafterIndex) {
          updatedCraftersList.splice(crafterIndex, 1);
          setCrafters(updatedCraftersList);

          setLoading(false);

          if (userHaveAGoogleWorkspaceAccount) {
            await Swal.fire({
              icon: "warning",
              title: "L'utilisateur a un compte Google Workspace",
              text: `Attention ! ${profile?.firstname} ${profile?.lastname} a un compte Google Workspace. Il ne faut pas oublier de le supprimer si besoin.`,
              showConfirmButton: true,
              confirmButtonText: "D'accord",
              confirmButtonColor: "#282828",
              showCancelButton: false,
              customClass: "popup",
            });
          }

          void toast("success", "Le compte a été supprimé");
          navigate("/directory");
        }
      }
    } catch (error: any) {
      void toast("error", error);
    }
  };

  const handleCancelRolesChanges = (): void => {
    setChangesHaveBeenMadeOnRoles(false);
    setActiveRoles([...initialRoles]);
  };

  const handleProfilePictureEdition = (): void =>
    navigate(`/directory/${email}/profilePicture`);

  const profileInformationsOption: OptionContent = {
    name: "Informations",
    nameIcon: <IconUserCircle />,
    content: (
      <form onSubmit={handleSubmit(handleSaveChanges)}>
        <Toggleable
          id="informationsToggleable"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable}
          label="Informations personnelles"
          fadeEffect
          icon={<IconUser />}
        >
          <>
            {isLoading && <CardLoading />}
            <div className="open-to-work-selection">
              <div className="cntr">
                <input
                  type="checkbox"
                  id="cbx-openToWork"
                  className="hidden-xs-up cbx-x"
                  checked={openToWork}
                  onChange={(e) => {
                    setOpenToWork(e.target.checked);
                    setChangesHaveBeenMadeOnProfile(true);
                  }}
                />
                <label htmlFor="cbx-openToWork" className="cbx"></label>
              </div>
              <label
                className="bold"
                style={{ cursor: "pointer" }}
                htmlFor="cbx-openToWork"
              >
                Open to work
              </label>
            </div>
            {informationsInputs.map((input: ProfileInput, key: any) => {
              const value: string = profile
                ? input.property !== "birth_date"
                  ? (profile[input.property] as any)
                  : getRawDate(profile[input.property] as unknown as number)
                : "";
              return (
                <div className="input-group" key={key}>
                  <ProfileIcon property={input.property} />
                  {!input.isTextarea ? (
                    <input
                      type={input.type}
                      placeholder={input.placeholder}
                      defaultValue={value}
                      readOnly={input.readOnly}
                      {...register(input.property)}
                      onChange={() => handleInputChange(input.property)}
                    />
                  ) : (
                    <textarea
                      placeholder={input.placeholder}
                      defaultValue={
                        profile ? (profile[input.property] as string) : ""
                      }
                      readOnly={input.readOnly}
                      {...register(input.property)}
                      onChange={() => handleInputChange(input.property)}
                    ></textarea>
                  )}
                </div>
              );
            })}
          </>
        </Toggleable>
        <Toggleable
          id="activityToggleable"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable}
          label="Activité"
          icon={<IconActivity />}
          fadeDelay={100}
        >
          <>
            {isLoading && <CardLoading />}
            {activityInputs.map((input: ProfileInput, key: any) => {
              return (
                <div className="input-group" key={key}>
                  <ProfileIcon property={input.property} />
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    defaultValue={
                      profile ? (profile[input.property] as string) : ""
                    }
                    readOnly={input.readOnly}
                    {...register(input.property)}
                    onChange={() => setChangesHaveBeenMadeOnProfile(true)}
                  />
                </div>
              );
            })}
            <div className="input-group">
              <IconUserStar />
              <select
                value={
                  profileProfessionalStatus?.length
                    ? profileProfessionalStatus
                    : "none"
                }
                onChange={(e) => {
                  setProfileProfessionalStatus(e.target.value);
                  setChangesHaveBeenMadeOnProfile(true);
                }}
              >
                <option value="none" disabled>
                  Statut Professionnel (veuillez sélectionner)
                </option>
                <option value="Indépendant⸱e">Indépendant</option>
                <option value="Salarié⸱e">Salarié⸱e</option>
              </select>
            </div>
            {profileProfessionalStatus === "Salarié⸱e" && (
              <div className="input-group">
                <IconBuildingEstate />
                <input
                  type="text"
                  placeholder="Nom de l'entreprise"
                  defaultValue={profile?.company}
                  {...register("company")}
                  onChange={() => setChangesHaveBeenMadeOnProfile(true)}
                />
              </div>
            )}
          </>
        </Toggleable>
        {changesHaveBeenMadeOnProfile && (
          <div className="actions floating">
            <button
              type="reset"
              className="secondary"
              onClick={handleCancelProfileChanges}
            >
              Supprimer les modifications
              <IconTrash />
            </button>
            <button type="submit" className="primary">
              Enregistrer les modification
              <IconDeviceFloppy />
            </button>
          </div>
        )}
      </form>
    ),
  };

  const profileCvOption: OptionContent = {
    name: "CV",
    nameIcon: <IconFileInvoice />,
    content: (
      <CvUploader
        downloadUrl={cvFilePath}
        profile={profile}
        email={profile?.email ?? ""}
      />
    ),
  };

  const userRolesOption: OptionContent = {
    name: "Rôles",
    nameIcon: <IconSparkles />,
    content: (
      <>
        {rolesLoading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleSubmit(handleSubmitRoles)}>
            <div className="roles">
              {validRoles.map((role) => {
                return (
                  <div key={role.id}>
                    <span className="role">
                      <div className="cntr">
                        <input
                          type="checkbox"
                          id={`cbx-${role.id}`}
                          className="hidden-xs-up cbx-x"
                          onChange={(e) =>
                            handleRolesChanges(role, e.target.checked)
                          }
                          checked={activeRoles.some(
                            (activeRole) => role.id === activeRole.id,
                          )}
                        />
                        <label
                          htmlFor={`cbx-${role.id}`}
                          className="cbx"
                        ></label>
                      </div>
                      {role.name}
                      <IconInfoSquareRounded data-tooltip-id={role.id} />
                    </span>
                    <Tooltip id={role.id}>{role.description}</Tooltip>
                  </div>
                );
              })}
            </div>
            {changesHaveBeenMadeOnRoles && (
              <div className="actions floating">
                <button
                  type="reset"
                  className="secondary"
                  onClick={handleCancelRolesChanges}
                >
                  Supprimer les modifications
                  <IconTrash />
                </button>
                <button type="submit" className="primary">
                  Enregistrer les modification
                  <IconDeviceFloppy />
                </button>
              </div>
            )}
          </form>
        )}
      </>
    ),
  };

  const dangerZoneOption: OptionContent = {
    name: "Zone dangereuse",
    nameIcon: <IconAlertTriangle />,
    content: (
      <button className="danger" onClick={handleDeleteAccount}>
        Supprimer le compte de {profile?.firstname} {profile?.lastname}
        <IconTrash />
      </button>
    ),
  };

  return (
    <PageLayer>
      <header>
        <div className="icon" onClick={handleProfilePictureEdition}>
          {profile ? (
            <img
              className="avatar"
              alt="mysocraft avatar"
              src={profile.profilePicture}
            />
          ) : (
            <Skeleton height="110px" width="110px" />
          )}
        </div>
        <div className="text">
          <h1 className="page-title">
            {profile?.firstname}{" "}
            <span className="yellow">{profile?.lastname}</span>
          </h1>
          <Date />
        </div>
      </header>
      {loading && <CardLoading />}
      {profile ? (
        <BlockLayer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <span
              className="yellow has-icon"
              onClick={() => navigate("/directory")}
            >
              <IconArrowLeft />
              Retour à l&apos;annuaire
            </span>
          </div>
          <Tabs
            options={[
              profileInformationsOption,
              profileCvOption,
              userRolesOption,
              dangerZoneOption,
            ]}
          />
        </BlockLayer>
      ) : (
        <Loader />
      )}
    </PageLayer>
  );
};

export default DirectoryCrafter;
