import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconPhotoScan,
} from "@tabler/icons-react";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/common/Loader";
import Skeleton from "../components/common/Skeleton";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useDirectory } from "../providers/DirectoryProvider";
import { useHttp } from "../providers/HttpProvider";
import { useProfile } from "../providers/ProfileProvider";
import { Roles } from "../roles";
import toast from "../shared/popups/toast";
import { Profile } from "../types/profile";

interface ProfilePictureUploaderProps {}

const ProfilePictureUploader: FC<ProfilePictureUploaderProps> = () => {
  const [isOwnProfilePicture, setIsOwnProfilePicture] = useState<boolean>(true);
  const [previousPagePath, setPreviousPagePath] = useState<string>("/profile");
  const [newProfilePictureUrl, setNewProfilePictureUrl] = useState<string>();
  const { profile, roles, setProfile } = useProfile();
  const [currentProfile, setCurrentProfile] = useState<Profile>();
  const [dragging, setDragging] = useState<boolean>(false);
  const { crafters, setCrafters } = useDirectory();
  const [file, setFile] = useState<File>();
  const navigate = useNavigate();
  const { email } = useParams();
  const { put } = useHttp();

  useEffect(() => {
    if (!roles?.some((role) => role === Roles.ADMIN) && profile) {
      setCurrentProfile(profile);
      setIsOwnProfilePicture(true);
      setPreviousPagePath("/profile");
    } else {
      if (!email) {
        setCurrentProfile(profile);
        setIsOwnProfilePicture(true);
        setPreviousPagePath("/profile");
      } else {
        if (crafters) {
          const crafterProfile = crafters.find(
            (crafter) => crafter.email === email,
          );

          setIsOwnProfilePicture(false);
          setCurrentProfile(crafterProfile);
          setPreviousPagePath(`/directory/${crafterProfile?.email}`);
        }
      }
    }
  }, [roles, profile, crafters, email]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | null = (event.target.files ?? [null])[0];

    if (file) {
      const bufferUrl = URL.createObjectURL(file);
      setFile(file);
      setNewProfilePictureUrl(bufferUrl);
    }
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (newProfilePictureUrl && file && currentProfile && crafters) {
      try {
        const requestUrl = `/profiles/${isOwnProfilePicture ? "own" : email}/profilePicture`;
        let { updatedProfile } = await put(requestUrl, file);

        updatedProfile = {
          ...updatedProfile,
          profilePicture:
            updatedProfile.profilePicture + `?${new Date().getTime()}`,
        };

        setCurrentProfile(updatedProfile);

        if (isOwnProfilePicture) {
          setProfile(updatedProfile);
        } else {
          const updatedCraftersList = [...crafters];

          updatedCraftersList[
            crafters.findIndex(
              (crafter) => crafter.email === updatedProfile.email,
            )
          ] = updatedProfile;

          setCrafters(updatedCraftersList);
        }

        setNewProfilePictureUrl(undefined);
        void toast("success", "Photo de profil mise à jour !");
      } catch (error: any) {
        void toast("error", `Oups ! Une erreur est survenue : ${error}`);
      }
    }
  };

  const handleDragZoneHover = (e: any, dragging: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(dragging);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const file = e.dataTransfer.files[0];

    if (file) {
      const bufferUrl = URL.createObjectURL(file);
      setFile(file);
      setNewProfilePictureUrl(bufferUrl);
    }
  };

  if (!currentProfile) {
    return <Loader />;
  }

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconPhotoScan size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Photo de profil</h1>
          <span className="yellow bold">
            {currentProfile.firstname} {currentProfile.lastname}
          </span>
        </div>
      </header>
      <input onChange={(e) => handleFileChange(e)} type="file" id="file" />
      <BlockLayer>
        <label
          onDragEnter={(e) => handleDragZoneHover(e, true)}
          onDragLeave={(e) => handleDragZoneHover(e, false)}
          onDragOver={(e) => handleDragZoneHover(e, true)}
          onDrop={handleDrop}
          className={
            dragging
              ? "profile-picture-uploader dragging"
              : "profile-picture-uploader"
          }
          htmlFor="file"
        >
          {currentProfile.profilePicture ? (
            <img src={currentProfile.profilePicture} alt="current" />
          ) : (
            <Skeleton height="150px" width="150px" />
          )}
          <IconArrowRight size={100} />
          {newProfilePictureUrl ? (
            <img src={newProfilePictureUrl} alt="new" />
          ) : (
            <div className="empty-new-profile-picture"></div>
          )}
        </label>
        <div className="actions">
          <button
            onClick={() => navigate(previousPagePath)}
            className="secondary"
          >
            <IconArrowLeft />
            Retour au profil
          </button>
          <button
            className="primary"
            disabled={!newProfilePictureUrl}
            onClick={handleSaveChanges}
          >
            Enregistrer
            <IconCheck />
          </button>
        </div>
      </BlockLayer>
    </PageLayer>
  );
};

export default ProfilePictureUploader;
