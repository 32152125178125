import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { filterAllowedTabs } from "../shared/others/filterAllowedTabs";
import { tabs } from "../static/others/navigationTabs";
import {
  DoorsContextProps,
  DoorsProviderProps,
  DoorStatus,
} from "../types/doors";
import { useHttp } from "./HttpProvider";
import { useProfile } from "./ProfileProvider";

export const DoorsContext = createContext(undefined);

export const DoorsProvider = ({
  children,
}: DoorsProviderProps): ReactElement => {
  const [doors, setDoors] = useState<DoorStatus[]>();
  const { loading, get, post } = useHttp();
  const { roles } = useProfile();
  const { user } = useAuth0();

  useEffect(() => {
    const initDoors = async (): Promise<void> => {
      try {
        const statuses = (await get("/coworking/doors")).statuses as any[];

        setDoors(
          statuses.map((status) => {
            return {
              door: {
                name: status.doorStatus.door.name,
                id: status.doorStatus.door.id,
              },
              state: status.doorStatus.state,
              batteryPercentage: status.doorStatus.batteryPercentage,
            };
          }),
        );
      } catch (error: any) {
        const allowedErrorsStatuses = [403, 401];
        if (
          !allowedErrorsStatuses.some(
            (status) => status === error.response.status,
          )
        ) {
          throw new Error(error);
        }
      }
    };

    !loading &&
      filterAllowedTabs(roles, tabs).some((tab) => tab.name === "Coworking") &&
      void initDoors();
  }, [roles, user, loading, get]);

  const controlDoors = async (action: "lock" | "unlock"): Promise<void> => {
    const request = await post(`/coworking/doors:${action}`);

    setDoors(
      request.response.map((status: any) => {
        return {
          door: {
            name: status.doorStatus.door.name,
            id: status.doorStatus.door.id,
          },
          state: status.doorStatus.state,
          batteryPercentage: status.doorStatus.batteryPercentage,
        };
      }),
    );
  };

  const value: any = {
    doors,
    controlDoors,
  };

  return (
    <DoorsContext.Provider value={value}>{children}</DoorsContext.Provider>
  );
};

export const useDoors = (): DoorsContextProps => {
  const doorsContext = useContext<any>(DoorsContext);

  if (!doorsContext) {
    throw new Error("useDoors must be used within a DoorsProvider");
  }

  return doorsContext;
};
