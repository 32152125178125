import {
  IconActivity,
  IconBuilding,
  IconBuildingEstate,
  IconCheck,
  IconDeviceFloppy,
  IconFileInvoice,
  IconPin,
  IconShare,
  IconTrash,
  IconUser,
  IconUserCircle,
  IconUserStar,
  IconWorld,
} from "@tabler/icons-react";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CardLoading from "../components/common/CardLoading";
import Date from "../components/common/Date";
import Loader from "../components/common/Loader";
import Skeleton from "../components/common/Skeleton";
import Tabs, { OptionContent } from "../components/common/Tabs";
import Toggleable from "../components/common/Toggleable";
import CvUploader from "../components/profile/CvUploader";
import ProfileIcon from "../components/profile/ProfileIcon";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";
import { useProfile } from "../providers/ProfileProvider";
import { getRawDate } from "../shared/others/formatDate";
import toast from "../shared/popups/toast";
import { updateProfileObjectProperty } from "../shared/profile/updateProfileObjectProperty";
import { activityInputs } from "../static/profile/activityInputs";
import { informationsInputs } from "../static/profile/informationsInputs";
import { ProfileInput, Profile as ProfileProps } from "../types/profile";

const Profile = (): ReactElement => {
  const [profileProfessionalStatus, setProfileProfessionalStatus] =
    useState<string>();
  const [profileTechnicalSkills, setProfileTechnicalSkills] = useState<
    string[]
  >([]);
  const [changesHaveBeenMade, setChangesHaveBeenMade] =
    useState<boolean>(false);
  const [openToggleable, setOpenToggleable] = useState<string>(
    "informationsToggleable",
  );
  const [fieldsWithChanges, setFieldsWithChanges] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [street, setStreet] = useState<string>("");
  const [npa, setNpa] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [postalAddress, setPostalAddress] = useState<string>("");
  const [copyProfileLinkButtonText, setCopyProfileLinkButtonText] =
    useState<string>("Partager mon profil");
  const [copyProfileLinkButtonIcon, setCopyProfileLinkButtonIcon] =
    useState<React.ReactNode>(<IconShare />);
  const [openToWork, setOpenToWork] = useState<boolean>(false);
  const { profile, cvFilePath, editProfile } = useProfile();
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setProfileProfessionalStatus(profile?.professional_status);
    setProfileTechnicalSkills(
      profile?.technical_skills?.length
        ? profile?.technical_skills.split(";")
        : [],
    );
    setOpenToWork(profile?.openToWork ?? false);
  }, [profile]);

  useEffect(() => {
    if (profile?.postal_address) {
      const addressComponents = profile.postal_address.split(",");
      if (addressComponents.length === 4) {
        const [streetValue, npaValue, cityValue, countryValue] =
          addressComponents;
        setStreet(streetValue.trim());
        setNpa(npaValue.trim());
        setCity(cityValue.trim());
        setCountry(countryValue.trim());
      }
    }
  }, [profile?.postal_address]);

  useEffect(() => {
    const addressComponents = [street, npa, city, country];
    const newPostalAddress = addressComponents.filter(Boolean).join(", ");
    setPostalAddress(newPostalAddress);
  }, [street, npa, city, country]);

  const handleEditAvatar = async (): Promise<void> => {
    window.localStorage.removeItem("crafter");
    navigate("/profile/profilePicture");
  };

  const handleSaveChanges = async (formValues: ProfileProps): Promise<void> => {
    formValues.technical_skills = profileTechnicalSkills?.length
      ? profileTechnicalSkills.join(";")
      : "";

    Object.keys(formValues).forEach((key): void => {
      if (
        !fieldsWithChanges.some((field: string) => field === key) &&
        !String(formValues[key as keyof ProfileProps]).length &&
        key !== "technical_skills"
      ) {
        delete formValues[key as keyof ProfileProps];
      }
    });

    let updatedProfile: ProfileProps = {
      ...profile,
      postal_address: postalAddress,
    };

    Object.entries(formValues).forEach(([key, value]): void => {
      updatedProfile = updateProfileObjectProperty(
        key as keyof ProfileProps,
        value,
        updatedProfile,
      );
    });

    if (profileProfessionalStatus !== "none") {
      updatedProfile.professional_status = profileProfessionalStatus;
    }

    try {
      setIsLoading(true);

      setChangesHaveBeenMade(false);
      await editProfile({
        ...updatedProfile,
        openToWork,
      });

      setIsLoading(false);
      toast("success", "Modifications enregistrées !");
    } catch (error: any) {
      toast("error", error);
    }
  };

  const handleInputChange = (changedInput: string): void => {
    setChangesHaveBeenMade(true);

    const updatedChangedFieldsList = [...fieldsWithChanges];
    updatedChangedFieldsList.push(changedInput);

    setFieldsWithChanges(updatedChangedFieldsList);
  };

  const handleCancelChanges = (): void => {
    const newTechnicalSkills = profile?.technical_skills?.length
      ? profile?.technical_skills.split(";")
      : [];
    setProfileTechnicalSkills(newTechnicalSkills);

    setProfileProfessionalStatus(profile?.professional_status);
    setChangesHaveBeenMade(false);
    reset();
  };

  const handleCopyProfileLink = async (): Promise<void> => {
    await navigator.clipboard.writeText(
      `https://socraft.community/profile/${profile.shortId}`,
    );
    setCopyProfileLinkButtonIcon(<IconCheck />);
    setCopyProfileLinkButtonText("Lien copié");

    setTimeout(() => {
      setCopyProfileLinkButtonIcon(<IconShare />);
      setCopyProfileLinkButtonText("Partager mon profil");
    }, 2000);
  };

  const profileInformationsOption: OptionContent = {
    name: "Informations",
    nameIcon: <IconUserCircle />,
    content: (
      <form onSubmit={handleSubmit(handleSaveChanges)}>
        <Toggleable
          id="informationsToggleable"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable}
          label="Vos informations"
          fadeEffect
          icon={<IconUser />}
        >
          <>
            {isLoading && <CardLoading />}
            <div className="open-to-work-selection">
              <div className="cntr">
                <input
                  type="checkbox"
                  id="cbx-openToWork"
                  className="hidden-xs-up cbx-x"
                  checked={openToWork}
                  onChange={(e) => {
                    setOpenToWork(e.target.checked);
                    setChangesHaveBeenMade(true);
                  }}
                />
                <label htmlFor="cbx-openToWork" className="cbx"></label>
              </div>
              <label
                className="bold"
                style={{ cursor: "pointer" }}
                htmlFor="cbx-openToWork"
              >
                Open to work
              </label>
            </div>
            {informationsInputs.map((input: ProfileInput, key: any) => {
              const value: string = profile
                ? input.property !== "birth_date"
                  ? (profile[input.property] as any)
                  : getRawDate(profile[input.property] as unknown as number)
                : "";
              return (
                <div className="input-group" key={key}>
                  <ProfileIcon property={input.property} />
                  {!input.isTextarea ? (
                    <input
                      type={input.type}
                      placeholder={input.placeholder}
                      defaultValue={value}
                      readOnly={input.readOnly}
                      {...register(input.property)}
                      onChange={() => handleInputChange(input.property)}
                    />
                  ) : (
                    <textarea
                      placeholder={input.placeholder}
                      defaultValue={
                        profile ? (profile[input.property] as string) : ""
                      }
                      readOnly={input.readOnly}
                      {...register(input.property)}
                      onChange={() => handleInputChange(input.property)}
                    ></textarea>
                  )}
                </div>
              );
            })}
            <br />
            <div className="input-grid">
              <div className="input-group">
                <IconBuilding />
                <input
                  type="text"
                  placeholder="Rue"
                  value={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                    setChangesHaveBeenMade(true);
                  }}
                />
              </div>
              <div className="input-group">
                <IconPin />
                <input
                  type="number"
                  placeholder="NPA"
                  value={npa}
                  onChange={(e) => {
                    setNpa(e.target.value);
                    setChangesHaveBeenMade(true);
                  }}
                />
              </div>
              <div className="input-group">
                <IconBuildingEstate />
                <input
                  type="text"
                  placeholder="Ville"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setChangesHaveBeenMade(true);
                  }}
                />
              </div>
              <div className="input-group">
                <IconWorld />
                <input
                  type="text"
                  placeholder="Pays"
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    setChangesHaveBeenMade(true);
                  }}
                />
              </div>
            </div>
          </>
        </Toggleable>
        <Toggleable
          id="activityToggleable"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable}
          label="Votre activité"
          icon={<IconActivity />}
          fadeDelay={100}
        >
          <>
            {isLoading && <CardLoading />}
            {activityInputs.map((input: ProfileInput, key: any) => {
              return (
                <div className="input-group" key={key}>
                  <ProfileIcon property={input.property} />
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    defaultValue={
                      profile ? (profile[input.property] as string) : ""
                    }
                    readOnly={input.readOnly}
                    {...register(input.property)}
                    onChange={() => setChangesHaveBeenMade(true)}
                  />
                </div>
              );
            })}
            <div className="input-group">
              <IconUserStar />
              <select
                value={
                  profileProfessionalStatus?.length
                    ? profileProfessionalStatus
                    : "none"
                }
                onChange={(e) => {
                  setProfileProfessionalStatus(e.target.value);
                  setChangesHaveBeenMade(true);
                }}
              >
                <option value="none" disabled>
                  Statut Professionnel (veuillez sélectionner)
                </option>
                <option value="Indépendant⸱e">Indépendant</option>
                <option value="Salarié⸱e">Salarié⸱e</option>
              </select>
            </div>
            {profileProfessionalStatus === "Salarié⸱e" && (
              <div className="input-group">
                <IconBuildingEstate />
                <input
                  type="text"
                  placeholder="Nom de l'entreprise"
                  defaultValue={profile?.company}
                  {...register("company")}
                  onChange={() => setChangesHaveBeenMade(true)}
                />
              </div>
            )}
          </>
        </Toggleable>
        {changesHaveBeenMade && (
          <div className="actions floating">
            <button
              type="reset"
              className="secondary"
              onClick={handleCancelChanges}
            >
              Supprimer les modifications
              <IconTrash />
            </button>
            <button type="submit" className="primary">
              Enregistrer les modification
              <IconDeviceFloppy />
            </button>
          </div>
        )}
      </form>
    ),
  };

  const profileCvOption: OptionContent = {
    name: "CV",
    nameIcon: <IconFileInvoice />,
    content: (
      <CvUploader
        downloadUrl={cvFilePath ?? ""}
        email={profile?.email ?? ""}
        own
      />
    ),
  };

  return (
    <PageLayer>
      <header>
        <div className="icon" onClick={handleEditAvatar}>
          {profile ? (
            <img
              className="avatar"
              alt="mysocraft avatar"
              src={profile.profilePicture}
            />
          ) : (
            <Skeleton height="110px" width="110px" />
          )}
        </div>
        <div className="text">
          <h1 className="page-title">Votre profil</h1>
          <Date />
        </div>
      </header>
      {profile ? (
        <BlockLayer>
          <span
            style={{ gap: "10px", cursor: "pointer" }}
            className="yellow bold flex align-center"
            onClick={handleCopyProfileLink}
          >
            {copyProfileLinkButtonText}
            {copyProfileLinkButtonIcon}
          </span>
          <br />
          <br />
          <Tabs options={[profileInformationsOption, profileCvOption]} />
        </BlockLayer>
      ) : (
        <Loader />
      )}
    </PageLayer>
  );
};

export default Profile;
