import { Roles } from "../../roles";
import { Tab } from "../../types/tab";

export const tabs: Tab[] = [
  {
    name: "Accueil",
    path: "/",
    index: 0,
  },
  {
    name: "Coworking",
    path: "/coworking",
    neededRoles: [Roles.ADMIN, Roles.PAYS, Roles.PARTNER],
    index: 1,
  },
  {
    name: "Profil",
    path: "/profile",
    index: 2,
  },
  {
    name: "Annuaire",
    path: "/directory",
    neededRoles: [Roles.ADMIN, Roles.PAYS, Roles.PARTNER],
    index: 3,
  },
  {
    name: "Comptes",
    path: "/accounts",
    neededRoles: [Roles.ADMIN],
    index: 4,
    hideIfRoleIsMissing: true,
  },
  {
    name: "Nouveautés",
    path: "/release-notes",
    index: 5,
  },
];
